@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 200;
  src: url("../src/assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 300;
  src: url("../src/assets/fonts/TitilliumWeb-LightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 400;
  src: url("../src/assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 600;
  src: url("../src/assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 700;
  src: url("../src/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: italic;
  font-weight: 700;
  src: url("../src/assets/fonts/TitilliumWeb-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 900;
  src: url("../src/assets/fonts/TitilliumWeb-Black.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 200;
  src: url("../src/assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: italic;
  font-weight: 200;
  src: url("../src/assets/fonts/TitilliumWeb-LightItalic.ttf")
    format("truetype");
}

/* Repeat the above pattern for the other font weights and styles */

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 900;
  src: url("../src/assets/fonts/TitilliumWeb-Black.ttf") format("truetype");
}

* {
  font-family: "TitilliumWeb" !important;
  outline: none !important;
  box-sizing: border-box;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  box-shadow: none !important;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  box-shadow: none !important;
}

.form-control,
.input-group > .form-floating,
.input-group > .form-select {
  box-shadow: none !important;
}

.navbar-app {
  position: sticky !important;
  top: 0 !important;
  z-index: 100;
}
.main-app {
  min-height: 100vh;
}
/* colors theme vars */

.blink {
  animation: blinker 0.3s linear infinite;
  text-shadow: rgba(255, 175, 2, 0.199) 0px 0px 2px;
  color: rgb(236, 198, 148) !important;
}

:root {
  --bg-colorone: #fff;
  --bg-colortwo: #dddddd;
  --bg-colorthree: #c0c0c0;
  --bg-colorfourth: #082032;
  --navbar: #fff;
  --bluecolor: #22577a;
  --bg-dropdown: rgb(231, 231, 231);
  --text-colorone: rgb(8, 8, 8);
  --text-colortwo: gray;
  --text-colorthree: white;
  --text-colorfifth: white;
  --bg-homebg: url(./assets/images/bgwhite.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage.svg");
  --btn-one: #22577a;
  --btn-one-hover: white;
  --orange: rgb(255, 174, 0);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/withdraw-light.jpg");
  --bgcurve: url("./assets/images/top-curve.svg");
  --egbot: url("./assets/images/egbot.jpg");
  --audit: url("./assets/images/bg-audit.jpg");
  --trans: url("./assets/images/transactionlight.svg");
  --profile: url("./assets/images/profile-light.svg");
  --contact: url("./assets/images/contact-light.svg");
  --logo: url("./assets/images/EEP_Logo.svg");
  --tradebg: #f7f7f7;
  --trade2nd: #fafafa;
  --trade3rd: #fbfeff;
  --trade4th: #8d8d8d;
  --trade5th: #494949;
  --tradered: #e40031;
  --tradegreen: #00da6d;
  --tradepair: #0070f0;
  --bordercl: #8383836b;
  --tradebtnbg: #49494981;
}

body[data-theme="light-theme"] {
  --bg-colorone: #fff;
  --bg-colortwo: #dddddd;
  --bg-colorthree: #c0c0c0;
  --bg-colorfourth: #082032;
  --navbar: #fff;
  --bluecolor: #22577a;
  --bg-dropdown: rgb(231, 231, 231);
  --text-colorone: rgb(8, 8, 8);
  --text-colortwo: gray;
  --text-colorthree: white;
  --text-colorfifth: white;
  --bg-homebg: url(./assets/images/bgwhite.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage.svg");
  --btn-one: #22577a;
  --btn-one-hover: white;
  --orange: rgb(255, 174, 0);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/withdraw-light.jpg");
  --bgcurve: url("./assets/images/top-curve.svg");
  --egbot: url("./assets/images/egbot.jpg");
  --audit: url("./assets/images/bg-audit.jpg");
  --trans: url("./assets/images/transactionlight.svg");
  --profile: url("./assets/images/profile-light.svg");
  --contact: url("./assets/images/contact-light.svg");
  --logo: url("./assets/images/EEP_Logo.svg");
  --tradebg: #f7f7f7;
  --trade2nd: #fafafa;
  --trade3rd: #fbfeff;
  --trade4th: #8d8d8d;
  --trade5th: #494949;
  --tradered: #e40031;
  --tradegreen: #00da6d;
  --tradepair: #0070f0;
  --bordercl: #8383836b;
  --tradebtnbg: #49494981;
}

body[data-theme="dark-theme"] {
  --bg-colorone: #083049;
  --bg-colortwo: #0e3850;
  --bg-colorthree: #003a59;
  --bg-colorfourth: #071d2e;
  --navbar: #083049;
  --bluecolor: #0e4366;
  --bg-dropdown: rgb(90, 90, 90);
  --text-colorone: white;
  --text-colortwo: gray;
  --text-colorthree: rgb(255, 255, 255);
  --text-colorfifth: white;
  --bg-homebg: url(./assets/images/bgdark.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage_dark.svg");
  --btn-one: #f8f8f8;
  --btn-one-hover: #22577a;
  --orange: rgb(255, 174, 0);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/withdraw-dark.jpg");
  --bgcurve: url("./assets/images/top-curve-dark.svg");
  --egbot: url("./assets/images/egbotdark.jpg");
  --audit: url("./assets/images/bg-auditdark.jpg");
  --trans: url("./assets/images/transactiondark.svg");
  --profile: url("./assets/images/profile-dark.svg");
  --contact: url("./assets/images/contact-dark.svg");
  --logo: url("./assets/images/EEP_Logo_W.svg");
  --tradebg: #062238;
  --trade2nd: #04141d;
  --trade3rd: #942424;
  --trade4th: #d1d1d1;
  --trade5th: #ffffff;
  --tradered: #e40031;
  --tradegreen: #00da6d;
  --tradepair: #00a2ff;
  --bordercl: #8383836b;
  --tradebtnbg: #49494981;
}

body[data-theme="vintage-theme"] {
  --bg-colorone: #eae5d9;
  --bg-colortwo: #eccfd5;
  --bg-colorthree: #d2d4de;
  --bg-colorfourth: #e1cfc6;
  --navbar: #eae5d9;
  --bluecolor: #812f58;
  --bg-dropdown: #e1cfc6;
  --text-colorone: #9a2c65;
  --text-colortwo: #db4392;
  --text-colorthree: #f3d3e2;
  --text-colorfifth: rgb(112, 17, 53);
  --bg-homebg: url(./assets/images/bgvintage.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage_vintage.svg");
  --btn-one: #f8f8f8;
  --btn-one-hover: #812f58;
  --orange: rgb(255, 174, 0);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/withdraw-vintage.jpg");
  --bgcurve: url("./assets/images/top-curve-vintage.svg");
  --egbot: url("./assets/images/egbotvintage.jpg");
  --audit: url("./assets/images/bg-auditvintage.jpg");
  --trans: url("./assets/images/transactionvintage.svg");
  --profile: url("./assets/images/profile-vintage.svg");
  --contact: url("./assets/images/contact-vintage.svg");
  --logo: url("./assets/images/EEP_Logo.svg");
  --tradebg: #f1d7ca;
  --trade2nd: #e1cfc6;
  --trade3rd: #e4e4e4;
  --trade4th: #500b0b;
  --trade5th: #3f1e04;
  --tradered: #f5073a;
  --tradegreen: #03a755;
  --tradepair: #cc0547;
  --bordercl: #8383836b;
  --tradebtnbg: #e0e0e081;
}

body[data-theme="cool-theme"] {
  --bg-colorone: #dfefce;
  --bg-colortwo: #adf1da;
  --bg-colorthree: #afecc0;
  --bg-colorfourth: #e9eca5;
  --navbar: #7ef76e;
  --bluecolor: #065f4a;
  --bg-dropdown: #e9eca5;
  --text-colorone: #0a796a;
  --text-colortwo: #21b678;
  --text-colorthree: #67b9a6;
  --text-colorfifth: rgb(11, 90, 57);
  --bg-homebg: url(./assets/images/bgcool.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage_cool.svg");
  --btn-one: #f8f8f8;
  --btn-one-hover: #0d7579;
  --orange: rgb(255, 174, 0);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/withdraw-cool.jpg");
  --bgcurve: url("./assets/images/top-curve-cool.svg");
  --egbot: url("./assets/images/egbotcool.jpg");
  --audit: url("./assets/images/bg-auditcool.jpg");
  --trans: url("./assets/images/transactioncool.svg");
  --profile: url("./assets/images/profile-cool.svg");
  --contact: url("./assets/images/contact-green.svg");
  --logo: url("./assets/images/EEP_Logo.svg");
  --tradebg: #bff3ce;
  --trade2nd: #afecc0;
  --trade3rd: #e4e4e4;
  --trade4th: #067550;
  --trade5th: #094232;
  --tradered: #e40031;
  --tradegreen: #14a138;
  --tradepair: #055786;
  --bordercl: #8383836b;
  --tradebtnbg: #eeeeeef3;
}

.loading {
  background-color: var(--bg-colorone);
  z-index: 500000;
  height: 120vh;
  width: 100vw;
  margin-top: -100px;
  position: absolute;
}
.loader {
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loading-img {
  background-image: var(--logo);
  background-position: center;
  background-size: 180px 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 200px;
  padding: 0 !important;
  margin: 0 !important;
}
