@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 200;
  src: url("../src/assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 300;
  src: url("../src/assets/fonts/TitilliumWeb-LightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 400;
  src: url("../src/assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 600;
  src: url("../src/assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 700;
  src: url("../src/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: italic;
  font-weight: 700;
  src: url("../src/assets/fonts/TitilliumWeb-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 900;
  src: url("../src/assets/fonts/TitilliumWeb-Black.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 200;
  src: url("../src/assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb";
  font-style: italic;
  font-weight: 200;
  src: url("../src/assets/fonts/TitilliumWeb-LightItalic.ttf")
    format("truetype");
}

/* Repeat the above pattern for the other font weights and styles */

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 900;
  src: url("../src/assets/fonts/TitilliumWeb-Black.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "TitilliumWeb", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "TitilliumWeb", "Courier New", monospace;
}
