.market-table-btn {
  width: 85px;
  height: 40px;
  background-color: var(--bluecolor) !important;
  text-align: center;
  border: 0 !important;
}

.market-table-btn:hover {
  background-color: var(--bg-colortwo) !important;
  color: var(--text-colorone) !important;
}

.table-head {
  background-color: var(--bg-colorthree);
  color: var(--text-colorone) !important;
}

.table-row {
  color: var(--text-colorone) !important;
}
