.fee-main {
  background-color: var(--bg-colorone);
  min-height: 80vh;
  margin-top: -80px;
  overflow: auto;

  .fees-module {
    color: var(--text-colorone) !important;
    margin-top: 100px;
    margin-bottom: 100px;

    .table-responsive {
      color: var(--text-colorone) !important;

      .table {
        color: var(--text-colorone) !important;
      }
    }
  }
}

.fee-main-m {
  background-color: var(--bg-colorone);
  min-height: 80vh;
  margin-top: -80px;
  overflow: auto;

  .fees-module {
    color: var(--text-colorone) !important;
    margin-top: 100px;
    margin-bottom: 100px;

    h4 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    .table-responsive {
      color: var(--text-colorone) !important;
      background-color: var(--bg-colorone) !important;


      table {
        .thead-dark {
          color: var(--text-colorone) !important;
          background-color: var(--bg-colorone) !important;
        }

        color: var(--text-colorone) !important;
        background-color: var(--bg-colorone) !important;
        font-size: 14px;
        font-weight: normal !important;

        thead {
          color: var(--text-colorone) !important;
          background-color: var(--bg-colorone) !important;
          font-size: 14px;
          font-weight: normal !important;
        }
      }
    }
  }
}