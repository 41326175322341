.market-main {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -30px;
  background-color: var(--bg-colorone);
  overflow: auto;

  .market-table {
    margin-bottom: 50px;
    margin-top: 60px;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }

  h5 {
    color: var(--text-colorone);
    margin-bottom: 20px;
  }

  .MuiTabs-indicator {
    display: none;
    background: var(--text-colorone) !important;
  }

  .Mui-selected {
    color: var(--text-colorone) !important;
  }

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .Mui-selected,
  .MuiTouchRipple-root {
    color: var(--text-colortwo);
  }

  button.Mui-selected .Mui-root::before {
    background-color: var(--text-colorone);
  }
}

.market-main-mobile {
  min-height: 100vh;
  margin-top: 47px;
  background-color: var(--bg-colorone);
  overflow: auto;
  padding-bottom: 100px;
}

.market-data-mobile {
  height: 200px;
  background-color: var(--bg-colortwo);
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  color: var(--text-colorone);
  font-size: 13px;
}