.guaranteeform-main {
  background-color: var(--bg-colorone);
  color: var(--text-colorone);
  margin-top: -30px;
  overflow: auto;
}
.guaranteeform-module {
  min-height: 80vh;
  margin-top: 50px;

  .form-control {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
.submit-btn {
  background: #22577a;
  width: 170px;
  height: 47px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;

  &:hover {
    background: #fff;
    transition: all 0.5s;
    color: #22577a;
    border: 1px #22577a solid;
  }
}
