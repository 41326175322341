.submit-btn {
  width: 140px;
  height: 40px;
  background: #22577a !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px #22577a solid !important;
  &:hover {
    background: white !important;
    color: #22577a !important;
    transition: all 0.5s;
    border: 1px #22577a solid;
  }
}

.brokerform-main {
  background-color: var(--bg-colorone);
  color: var(--text-colorone);

  .broker-title {
    color: var(--text-colorone);
    background-color: var(--bg-colorone);
    margin-top: 50px;
  }
}

.broker-main {
  background-color: var(--bg-colorone);
  color: var(--text-colorone);
  margin-top: -50px;
  overflow: auto;

  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
    ) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
