.notification-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  background-image: url(../../assets/images/notificationbg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -30px;
  overflow: auto;

  .notification-module {
    background-color: var(--bg-colortwo);
    max-width: 650px;
    min-height: 600px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
ul {
  .notif-li {
    color: var(--text-colorone) !important;
    font-size: 14px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;

    .time-color {
      color: var(--text-colortwo);
    }
  }
}
