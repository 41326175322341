.modal-dialog {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 300px !important;

  .modal-content {
    width: 100%;
    color: var(--text-colorone) !important;
  }

  .modal-footer {
    .btn-success {
      background-color: var(--bg-colortwo);
      border: 0px;

      &:hover {
        background-color: var(--bg-colorthree);
      }
    }
  }
}
