.tradingview {
    background-color: var(--bg-colorone);
    margin-top: -50px;
    overflow: auto;
    color: var(--text-colorone);
}

.tradingview-m {
    margin-top: 100px;
    color: var(--text-colorone);
    text-align: justify;

}