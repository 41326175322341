.trade-main {
  min-height: 90vh;
  margin-top: -50px;
  overflow: auto;
  background-color: var(--trade2nd);

  .trade-module {
    overflow-x: hidden;
    margin-top: 55px;
    background-color: var(--trade2nd);
    min-height: 70vh;
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 40px;
    border: 1px solid var(--bordercl);
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    .simplebar-track.simplebar-horizontal {
      display: none;
    }

    .MuiBox-root {
      padding: 0 !important;
      margin: 0px !important;
      border: 0;
    }

    .trade-section-controller {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .topprice-in-trade {
      height: 60px;
      padding: 0;
      margin: 0 !important;
    }

    .orderlist-in-trade {
      //   width: 425px;
      margin-top: 0 !important;
    }

    .chart-main {
      //   width: 850px;
      height: 450px;
    }

    .toppricedata-main {
      background-color: var(--trade2nd);
      height: 60px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .pairname-top {
        // border: 1px orange solid;
        height: 35px;
        margin-left: 20px;
        margin-top: 8px;
        width: 120px !important;
        font-weight: bold;
        color: var(--tradepair);
        font-size: 20px !important;
      }

      .toppricedata {
        // border: 1px orange solid;
        height: 45px;
        margin-left: 5px;
        margin-top: 10px;
        width: 120px !important;
        font-weight: bold;
        color: var(--tradegreen);
        font-size: 14px !important;
        line-height: 10px;

        .priceusdt {
          color: var(--trade4th);
        }

        .data24h-title {
          color: var(--orange);
        }

        .change24h-data {
          color: var(--tradegreen);
        }

        .data24h-data {
          color: var(--trade5th);
        }
      }

      .toppricedata-volume {
        // border: 1px orange solid;
        height: 45px;
        margin-left: 5px;
        margin-top: 10px;
        width: 180px !important;
        font-weight: bold;
        color: var(--tradegreen);
        font-size: 14px !important;
        line-height: 10px;

        .data24h-data {
          color: var(--trade5th);
        }

        .data24h-title {
          color: var(--orange);
        }
      }
    }

    .userorderlist-table {
      border: 1px orange solid;

      height: 850px;
      margin-left: 10px;
    }
  }

  /// order list ///
  .orderlist-main {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;

    .Mui-selected {
      color: var(--orange) !important;
    }

    .order-price-in-orderlist {
      color: var(--tradered);
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-amount-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 12px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-total-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 12px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .top-title-orderlist-first {
      width: 33%;
      text-align: left;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-second {
      width: 33%;
      text-align: right;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-third {
      width: 33%;
      text-align: right;
      font-size: 13px;
      color: var(--trade5th);
    }

    .last-price-orderlisl {
      font-size: 16px;
      //   font-weight: bold;
      background-color: var(--tradebg);
      color: var(--tradegreen);
      height: 30px;
      border-radius: 25px;
      line-height: 40px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 30px;
    }

    .last-price-usd-orderlist {
      font-size: 15px;
      color: var(--trade5th);
      padding-left: 25px;
      font-weight: normal;
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }
  }

  //////////buy sell module /////////////

  .buy-sell-module-main {
    width: 585px;
    min-height: 360px;
    padding: 0 !important;
    // justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }
  }

  .buy-sell-module {
    width: 100%;
    height: 300px;
    padding: 0 !important;
    justify-content: center;
  }

  .buy-side-module {
    width: 45%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .sell-side-module {
    width: 45%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .available-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    color: var(--trade5th);
    font-size: 14px;
  }
  .buy-sell-module-main {
    .input-group-text {
      display: flex;
      align-items: center;
      padding-right: 2px;
      padding-left: 2px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      width: 50px;
      height: 30px;
      color: var(--trade4th);
      text-align: center;
      white-space: nowrap;
      background-color: var(--trade2nd);
      border: 0px solid #ced4da;
      border-bottom: 1px solid var(--tradepair);
      border-radius: 0;
    }

    .form-control {
      display: block;
      height: 30px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--trade5th);
      background-color: var(--trade2nd);
      background-clip: padding-box;
      border: 0px solid #ced4da;
      border-bottom: 1px solid var(--tradepair);
      appearance: none;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .slider-trade {
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .btn-limit-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 66px;
    }

    .btn-limit-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 66px;
    }

    .btn-market-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 67px;
    }

    .btn-market-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 67px;
    }

    .btn-stoplimit-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 32px;
    }

    .btn-stoplimit-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 32px;
    }
  }

  /////pair lists////
  .pairlist-main {
    // margin-left: 160px;
    width: 300px;
    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }

    .order-price-in-orderlist {
      color: var(--tradered);
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-amount-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 13px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-total-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 13px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .top-title-orderlist-first {
      width: 33%;
      text-align: left;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-second {
      width: 33%;
      text-align: center;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-third {
      width: 33%;
      text-align: center;
      font-size: 13px;
      padding-left: 15px;
      color: var(--trade5th);
    }

    .last-price-orderlisl {
      font-size: 16px;
      font-weight: bold;
      background-color: var(--tradebg);
      color: var(--tradegreen);
      height: 40px;
      border-radius: 25px;
      line-height: 40px;
    }

    .last-price-usd-orderlist {
      font-size: 15px;
      color: var(--trade5th);
      padding-left: 25px;
      font-weight: normal;
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }
    .pair-list {
      height: 350px;
    }

    .fav-pair-list {
      height: 350px;
    }
  }

  ////spacer////

  .spacing {
    height: 50px;
  }

  ///// order history

  .orderhistory-main {
    // margin-left: 160px;
    width: 294px;
    background-color: var(--trade2nd);
    height: 220px;
    padding: 0 !important;

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }

    .orderhistory-module {
      margin-right: 0px;
      padding: 0;
    }

    .top-title-orderhistory-first {
      width: 33%;
      text-align: left;
      font-size: 13px;
      padding-top: 10px;
      color: var(--trade5th);
    }

    .top-title-orderhistory-second {
      width: 33%;
      text-align: right;
      font-size: 13px;
      padding: 10px;
      color: var(--trade5th);
    }

    .top-title-orderhistory-third {
      width: 33%;
      text-align: right;
      font-size: 13px;
      padding: 10px;
      color: var(--trade5th);
    }

    .order-price-in-orderhistory {
      font-size: 13px;
      font-weight: 600;
      padding-left: 10px;
      padding-bottom: 2px;
      color: var(--tradegreen);
      text-align: left;
    }

    .order-amount-in-orderhistory {
      font-size: 13px;
      font-weight: 600;
      padding-bottom: 2px;
      color: var(--trade5th);
      text-align: right;
    }

    .order-time-in-orderhistory {
      font-size: 13px;
      font-weight: 600;
      padding-right: 10px;
      padding-bottom: 2px;
      color: var(--trade5th);
    }
  }
  .container-openorder {
    background-color: var(--tradebg);
    height: 350px;
    width: 1250px !important;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 50px;
    .openorder-main {
      width: auto;

      .MuiButtonBase-root,
      .MuiTab-root,
      .MuiTab-textColorPrimary {
        color: var(--trade4th);
      }

      .Mui-selected {
        color: var(--orange) !important;
      }
    }
  }
}

// style B

.trade-main {
  min-height: 90vh;
  margin-top: -50px;
  overflow: auto;
  background-color: var(--trade2nd);

  .trade-moduleB {
    overflow-x: hidden;
    margin-top: 55px;
    background-color: var(--trade2nd);
    min-height: 70vh;
    width: 1050px;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 40px;
    border: 1px solid var(--bordercl);
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    .simplebar-track.simplebar-horizontal {
      display: none;
    }

    .chart-B {
      padding-left: 5px;
    }

    .MuiBox-root {
      padding: 0 !important;
      margin: 0px !important;
      border: 0;
    }

    .trade-section-controller {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .topprice-in-trade {
      height: 60px;
      padding: 0;
      margin: 0 !important;
    }

    .orderlist-in-trade {
      //   width: 425px;
      margin-top: 0 !important;
    }

    .chart-main {
      //   width: 850px;
      height: 450px;
    }

    .toppricedata-mainB {
      background-color: var(--trade2nd);
      height: 60px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .pairname-topB {
        // border: 1px orange solid;
        height: 20px;
        margin-left: 10px;
        margin-top: 18px;
        width: 80px !important;
        font-weight: bold;
        color: var(--tradepair);
        font-size: 16px !important;
      }

      .toppricedataB {
        // border: 1px orange solid;
        height: 45px;
        margin-left: 5px;
        margin-top: 10px;
        width: 90px !important;
        font-weight: bold;
        color: var(--tradegreen);
        font-size: 12px !important;
        line-height: 10px;

        .priceusdt {
          color: var(--trade4th);
        }

        .data24h-title {
          color: var(--orange) !important;
        }

        .change24h-dataB {
          color: var(--tradegreen);
        }

        .data24h-data {
          color: var(--trade5th);
        }
      }

      .toppricedata-volumeB {
        // border: 1px orange solid;
        height: 45px;
        margin-left: 5px;
        margin-top: 10px;
        width: 140px !important;
        font-weight: bold;
        color: var(--tradegreen);
        font-size: 12px !important;
        line-height: 10px;

        .data24h-data {
          color: var(--trade5th);
        }

        .data24h-title {
          color: var(--orange);
        }
      }
    }

    .userorderlist-table {
      border: 1px orange solid;
      height: 850px;
      margin-left: 10px;
    }
  }

  /// order list ///
  .orderlist-mainB {
    width: 270px;
    padding: 0 !important;
    margin-left: 10px;
    .Mui-selected {
      color: var(--orange) !important;
    }

    .order-price-in-orderlist {
      color: var(--tradered);
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-amount-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 12px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-total-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 12px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .top-title-orderlist-first {
      width: 33%;
      text-align: left;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-second {
      width: 33%;
      text-align: center;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-third {
      width: 33%;
      text-align: right;
      font-size: 13px;
      color: var(--trade5th);
    }

    .last-price-orderlisl {
      font-size: 16px;
      //   font-weight: bold;
      background-color: var(--tradebg);
      color: var(--tradegreen);
      height: 30px;
      border-radius: 25px;
      line-height: 40px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 30px;
    }

    .last-price-usd-orderlist {
      font-size: 15px;
      color: var(--trade5th);
      padding-left: 25px;
      font-weight: normal;
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }
  }

  //////////buy sell module /////////////

  .buy-sell-module-mainB {
    width: 510px;
    min-height: 360px;
    padding: 0 !important;
    // justify-content: center;
    margin-left: auto;
    // margin-right: auto;

    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }
  }

  .buy-sell-moduleB {
    width: 100%;
    height: 300px;
    padding: 0 !important;
    justify-content: flex-end;
  }

  .buy-side-moduleB {
    width: 45%;
    // padding-left: 1px;
    // padding-right: 1px;
  }

  .sell-side-moduleB {
    width: 45%;
    // padding-left: 1px;
    // padding-right: 1px;
  }

  .available-sectionB {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    color: var(--trade5th);
    font-size: 12px;
  }
  .buy-sell-module-mainB {
    .input-group-text {
      display: flex;
      align-items: center;
      padding-right: 2px;
      padding-left: 2px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      width: 50px;
      height: 30px;
      color: var(--trade4th);
      text-align: center;
      white-space: nowrap;
      background-color: var(--trade2nd);
      border: 0px solid #ced4da;
      border-bottom: 1px solid var(--tradepair);
      border-radius: 0;
    }

    .form-control {
      display: block;
      height: 30px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--trade5th);
      background-color: var(--trade2nd);
      background-clip: padding-box;
      border: 0px solid #ced4da;
      border-bottom: 1px solid var(--tradepair);
      appearance: none;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .slider-trade {
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .btn-limit-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 39px;
    }

    .btn-limit-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 39px;
    }

    .btn-market-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 73px;
    }

    .btn-market-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 73px;
    }

    .btn-stoplimit-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 5px;
    }

    .btn-stoplimit-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 5px;
    }
  }

  /////pair lists////
  .pairlist-mainB {
    margin-left: 110px;
    width: 400px;
    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }

    .order-price-in-orderlist {
      color: var(--tradered);
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-amount-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 13px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-total-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 13px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .top-title-orderlist-first {
      width: 33%;
      text-align: left;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-second {
      width: 33%;
      text-align: center;
      font-size: 13px;
      color: var(--trade5th);
    }

    .top-title-orderlist-third {
      width: 33%;
      text-align: center;
      font-size: 13px;
      padding-left: 15px;
      color: var(--trade5th);
    }

    .last-price-orderlisl {
      font-size: 16px;
      font-weight: bold;
      background-color: var(--tradebg);
      color: var(--tradegreen);
      height: 40px;
      border-radius: 25px;
      line-height: 40px;
    }

    .last-price-usd-orderlist {
      font-size: 15px;
      color: var(--trade5th);
      padding-left: 25px;
      font-weight: normal;
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }
    .pair-list {
      height: 350px;
    }

    .fav-pair-list {
      height: 350px;
    }
  }

  ////spacer////

  .spacing {
    height: 54px;
  }

  ///// order history

  .orderhistory-mainB {
    width: 345px;
    background-color: var(--trade2nd);
    height: 285px;
    padding: 0 !important;
    margin-left: 30px;
    margin-top: 8px;

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }

    .orderhistory-module {
      margin-right: 0px;
      padding: 0;
    }

    .top-title-orderhistory-first {
      width: 33%;
      text-align: left;
      font-size: 13px;
      padding-top: 10px;
      color: var(--trade5th);
    }

    .top-title-orderhistory-second {
      width: 33%;
      text-align: right;
      font-size: 13px;
      padding: 10px;
      color: var(--trade5th);
    }

    .top-title-orderhistory-third {
      width: 33%;
      text-align: right;
      font-size: 13px;
      padding: 10px;
      color: var(--trade5th);
    }

    .order-price-in-orderhistory {
      font-size: 13px;
      font-weight: 600;
      padding-left: 10px;
      padding-bottom: 2px;
      color: var(--tradegreen);
      text-align: left;
    }

    .order-amount-in-orderhistory {
      font-size: 13px;
      font-weight: 600;
      padding-bottom: 2px;
      color: var(--trade5th);
      text-align: right;
    }

    .order-time-in-orderhistory {
      font-size: 13px;
      font-weight: 600;
      padding-right: 10px;
      padding-bottom: 2px;
      color: var(--trade5th);
    }
  }
  .container-openorderB {
    background-color: var(--tradebg);
    height: 350px;
    // width: 1052px !important;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 50px;
    .openorder-main {
      width: auto;

      .MuiButtonBase-root,
      .MuiTab-root,
      .MuiTab-textColorPrimary {
        color: var(--trade4th);
      }

      .Mui-selected {
        color: var(--orange) !important;
      }
    }
  }
}

// style C

.trade-main {
  min-height: 90vh;
  margin-top: -50px;
  overflow: auto;
  background-color: var(--trade2nd);

  .trade-moduleC {
    overflow-x: hidden;
    margin-top: 55px;
    background-color: var(--trade2nd);
    min-height: 70vh;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 40px;
    border: 1px solid var(--bordercl);
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    .simplebar-track.simplebar-horizontal {
      display: none;
    }

    .chart-C {
      padding-left: 5px;
    }

    .MuiBox-root {
      padding: 0 !important;
      margin: 0px !important;
      border: 0;
    }

    .trade-section-controller {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .topprice-in-trade {
      height: 60px;
      padding: 0;
      margin: 0 !important;
    }

    .orderlist-in-trade {
      //   width: 425px;
      margin-top: 0 !important;
    }

    .chart-main {
      //   width: 850px;
      height: 450px;
    }

    .toppricedata-mainB {
      background-color: var(--trade2nd);
      height: 60px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .pairname-topB {
        // border: 1px orange solid;
        height: 20px;
        margin-left: 10px;
        margin-top: 18px;
        width: 80px !important;
        font-weight: bold;
        color: var(--tradepair);
        font-size: 16px !important;
      }

      .toppricedataB {
        // border: 1px orange solid;
        height: 45px;
        margin-left: 5px;
        margin-top: 10px;
        width: 90px !important;
        font-weight: bold;
        color: var(--tradegreen);
        font-size: 12px !important;
        line-height: 10px;

        .priceusdt {
          color: var(--trade4th);
        }

        .data24h-title {
          color: var(--orange) !important;
        }

        .change24h-dataB {
          color: var(--tradegreen);
        }

        .data24h-data {
          color: var(--trade5th);
        }
      }

      .toppricedata-volumeB {
        // border: 1px orange solid;
        height: 45px;
        margin-left: 5px;
        margin-top: 10px;
        width: 140px !important;
        font-weight: bold;
        color: var(--tradegreen);
        font-size: 12px !important;
        line-height: 10px;

        .data24h-data {
          color: var(--trade5th);
        }

        .data24h-title {
          color: var(--orange);
        }
      }
    }

    .userorderlist-table {
      border: 1px orange solid;
      height: 850px;
      margin-left: 10px;
    }
  }

  /// order list ///
  .orderlist-mainC {
    // width: 200px;
    width: 107%;
    padding: 0 !important;
    margin-left: 10px;
    .Mui-selected {
      color: var(--orange) !important;
    }

    .order-price-in-orderlist {
      color: var(--tradered);
      font-weight: 600;
      font-size: 11px;
      text-align: left;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-amount-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 11px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-total-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 11px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .top-title-orderlist-first {
      width: 33%;
      text-align: left;
      font-size: 11px;
      color: var(--trade5th);
    }

    .top-title-orderlist-second {
      width: 33%;
      text-align: center;
      font-size: 11px;
      color: var(--trade5th);
    }

    .top-title-orderlist-third {
      width: 30%;
      text-align: right;
      font-size: 11px;
      color: var(--trade5th);
    }

    .last-price-orderlisl {
      font-size: 13px;
      //   font-weight: bold;
      background-color: var(--tradebg);
      color: var(--tradegreen);
      height: 30px;
      border-radius: 25px;
      line-height: 40px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 30px;
    }

    .last-price-usd-orderlist {
      font-size: 12px;
      color: var(--trade5th);
      padding-left: 20px;
      font-weight: normal;
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }
  }

  //////////buy sell module /////////////

  .buy-sell-module-mainC {
    width: 100%;
    max-width: 500px;
    min-height: 370px;
    padding: 0 !important;
    // justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }
  }

  #buysell-tab-0 {
    color: var(--tradegreen) !important;
  }

  #buysell-tab-1 {
    color: var(--tradered) !important;
  }
  .buy-sell-module-mainC2 {
    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
      min-height: 30px !important;
      max-height: 30px !important;
      min-width: 110px !important;
      max-width: 210px !important;
      width: 50%;
    }

    .Mui-selected {
      background-color: var(--tradebtnbg);
      border-radius: 25px;
    }
  }

  .buy-sell-moduleC {
    width: 100%;
    height: 380px;
    padding: 0 !important;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
  }

  .buy-side-moduleC {
    width: 100%;
  }

  .sell-side-moduleC {
    width: 100%;
  }

  .available-sectionB {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    color: var(--trade5th);
    font-size: 12px;
  }
  .buy-sell-module-mainC {
    .input-group-text {
      display: flex;
      align-items: center;
      padding-right: 2px;
      padding-left: 2px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      width: 50px;
      height: 30px;
      color: var(--trade4th);
      text-align: center;
      white-space: nowrap;
      background-color: var(--trade2nd);
      border: 0px solid #ced4da;
      border-bottom: 1px solid var(--tradepair);
      border-radius: 0;
    }

    .form-control {
      display: block;
      height: 30px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--trade5th);
      background-color: var(--trade2nd);
      background-clip: padding-box;
      border: 0px solid #ced4da;
      border-bottom: 1px solid var(--tradepair);
      appearance: none;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .slider-trade {
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .btn-limit-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 39px;
    }

    .btn-limit-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 39px;
    }

    .btn-market-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 73px;
    }

    .btn-market-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 73px;
    }

    .btn-stoplimit-sell {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradered);
      border: 0;
      margin-top: 5px;
    }

    .btn-stoplimit-buy {
      width: 100%;
      height: 35px;
      line-height: 10px;
      background-color: var(--tradegreen);
      border: 0;
      margin-top: 5px;
    }
  }

  /////pair lists////
  .pairlist-mainC {
    margin-left: 10px;
    // width: 300px;
    width: 100%;
    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }

    .order-price-in-orderlist {
      color: var(--tradered);
      font-weight: 600;
      font-size: 11px;
      text-align: left;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-amount-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 11px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .order-total-in-orderlist {
      color: var(--trade5th);
      font-weight: 600;
      font-size: 11px;
      text-align: right;
      width: 33%;
      padding-bottom: 2px;
    }

    .top-title-orderlist-first {
      width: 33%;
      text-align: left;
      font-size: 11px;
      color: var(--trade5th);
    }

    .top-title-orderlist-second {
      width: 33%;
      text-align: center;
      font-size: 11px;
      color: var(--trade5th);
    }

    .top-title-orderlist-third {
      width: 33%;
      text-align: center;
      font-size: 11px;
      padding-left: 15px;
      color: var(--trade5th);
    }

    .last-price-orderlisl {
      font-size: 12px;
      font-weight: bold;
      background-color: var(--tradebg);
      color: var(--tradegreen);
      height: 40px;
      border-radius: 25px;
      line-height: 40px;
    }

    .last-price-usd-orderlist {
      font-size: 12px;
      color: var(--trade5th);
      padding-left: 25px;
      font-weight: normal;
    }

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }
    .pair-list {
      height: 300px;
    }

    .fav-pair-list {
      height: 300px;
    }
  }

  ////spacer////

  .spacing {
    height: 54px;
  }

  ///// order history

  .orderhistory-mainC {
    // width: 270px;
    width: 100%;
    background-color: var(--trade2nd);
    height: 245px;
    padding: 0 !important;
    margin-left: 30px;
    margin-top: 8px;

    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      background-color: var(--trade4th);
    }

    .orderhistory-module {
      margin-right: 0px;
      padding: 0;
    }

    .top-title-orderhistory-first {
      width: 33%;
      text-align: left;
      font-size: 11px;
      padding-top: 10px;
      color: var(--trade5th);
    }

    .top-title-orderhistory-second {
      width: 33%;
      text-align: right;
      font-size: 11px;
      padding: 10px;
      color: var(--trade5th);
    }

    .top-title-orderhistory-third {
      width: 33%;
      text-align: right;
      font-size: 11px;
      padding: 10px;
      color: var(--trade5th);
    }

    .order-price-in-orderhistory {
      font-size: 11px;
      font-weight: 600;
      padding-left: 10px;
      padding-bottom: 2px;
      color: var(--tradegreen);
      text-align: left;
    }

    .order-amount-in-orderhistory {
      font-size: 11px;
      font-weight: 600;
      padding-bottom: 2px;
      color: var(--trade5th);
      text-align: right;
    }

    .order-time-in-orderhistory {
      font-size: 11px;
      font-weight: 600;
      padding-right: 10px;
      padding-bottom: 2px;
      color: var(--trade5th);
    }
  }
  .container-openorderC {
    background-color: var(--tradebg);
    height: 350px;
    // width: 819px !important;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 50px;
    .openorder-main {
      width: auto;

      .MuiButtonBase-root,
      .MuiTab-root,
      .MuiTab-textColorPrimary {
        color: var(--trade4th);
      }

      .Mui-selected {
        color: var(--orange) !important;
      }
    }
  }
}
.bottom-containerC {
  max-width: 1050px !important;
  width: 100%;
}

// style D

.trade-main {
  min-height: 90vh;
  margin-top: -50px;
  overflow: auto;
  background-color: var(--trade2nd);

  .trade-moduleD {
    overflow-x: hidden;
    margin-top: 55px;
    background-color: var(--trade2nd);
    min-height: 70vh;
    // max-width: 815px;
    min-width: 350px;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 40px;
    border: 1px solid var(--bordercl);
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);

    .buy-sell-moduleD {
      width: 100%;
      height: 275px;
      padding: 0 !important;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: auto;
    }

    .MuiBox-root {
      padding: 1px !important;
      align-items: center !important;
    }
    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary,
    .Mui-selected,
    .MuiButtonBase-root-MuiTab-root {
      padding: 0 !important;
      min-width: 70px !important;
      max-width: 90px !important;
      margin-left: auto;
      margin-right: auto;
    }
    .simplebar-track.simplebar-horizontal {
      display: none;
    }
    .topprice-in-tradeD {
      .toppricedata-mainD {
        max-width: 600px;

        .chart-d {
          width: 100%;
          min-width: 200px;
        }

        .pairname-topD {
          margin-top: 25px;
          font-size: 14px !important;
          font-weight: 600;
          margin-left: 10px;
          margin-right: -10px;
          color: var(--tradepair);
        }
        .toppricedataD {
          padding-top: 3px;
          font-size: 12px;
          font-weight: 600;
          color: var(--orange);

          .priceusdt {
            color: var(--text-colorone);
          }

          .change24h-data {
            color: var(--tradegreen);
          }
          .data24h-data {
            color: var(--trade5th);
          }
        }

        .toppricedata-volumeD {
          padding-top: 3px;
          font-size: 12px;
          color: var(--orange);
          font-weight: 600;
          .data24h-data {
            color: var(--trade5th);
          }
        }
      }
    }
    .accordion-button {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px;
      font-size: 14px;
      color: var(--text-colorone);
      text-align: left;
      background-color: var(--bg-colorone);
      border: 1px solid var(--bg-colorone) !important;
      border-radius: 0;
      overflow-anchor: none;
      transition: var(--bs-accordion-transition);
      box-shadow: none !important;
    }

    .accordion-body {
      padding: 0;
    }

    .accordion {
      --bs-accordion-color: #21252900;
      --bs-accordion-bg: #ffffff00;
      --bs-accordion-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
      --bs-accordion-border-color: #ffffff00;
      --bs-accordion-border-width: 1px;
      --bs-accordion-border-radius: 0.375rem;
      --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
      --bs-accordion-btn-padding-x: 1.25rem;
      --bs-accordion-btn-padding-y: 1rem;
      --bs-accordion-btn-color: #212529;
      --bs-accordion-btn-bg: var(--bs-accordion-bg);
      --bs-accordion-btn-icon: none;
      --bs-accordion-btn-icon-width: 1.25rem;
      --bs-accordion-btn-icon-transform: rotate(-180deg);
      --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
      --bs-accordion-btn-active-icon: none;
      --bs-accordion-btn-focus-border-color: #86b6fe00;
      --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem
        rgba(13, 110, 253, 0.25);
      --bs-accordion-body-padding-x: 1.25rem;
      --bs-accordion-body-padding-y: 1rem;
      --bs-accordion-active-color: #0c63e4;
      --bs-accordion-active-bg: #e7f1ff;
    }
    .accordion-button::after {
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-left: auto;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
    }
  }

  .btn-primary {
    font-size: 12px !important;
    background-color: var(--bluecolor) !important;
    border: 0px;
    color: var(--orange) !important;
    border-radius: 3px;
    margin-left: 5px;

    .btn-primary:hover {
      background-color: var(--bg-colorthree) !important;
      color: var(--orange);
    }
  }

  .dropdown-item {
    color: var(--orange);
    background-color: var(--bluecolor) !important;
    width: 280px;
    font-size: 12px;
    height: 20px !important;
    line-height: 14px;
  }
  .dropdown-menu {
    text-align: right !important;
    --bs-dropdown-bg: transparent #ffffff00;
    border: 0px;
    color: var(--orange);
  }
  .dropdown-item:hover {
    color: var(--tradepair);
    background-color: var(--bg-colorthree) !important;
    width: 280px;
    font-size: 12px;
  }
}
.order-price-in-orderlistD {
  text-align: left;
}

.order-amount-in-orderlistD {
  text-align: right !important;
}

.order-total-in-orderlistD {
  text-align: right !important;
}
.chart-btn {
  --bs-btn-padding-x: 80px !important;
}

/// order list ///
.orderlist-mainD {
  width: 100%;
  padding: 0 !important;
  .Mui-selected {
    color: var(--orange) !important;
  }

  .order-price-in-orderlist {
    color: var(--tradered);
    font-size: 10px;
    text-align: left;
    width: 33%;
  }

  .order-amount-in-orderlist {
    color: var(--trade5th);
    font-size: 10px;
    text-align: right;
    width: 33%;
  }

  .order-total-in-orderlist {
    color: var(--trade5th);
    font-size: 10px;
    text-align: right;
    width: 33%;
  }

  .top-title-orderlist-first {
    width: 33%;
    text-align: left;
    font-size: 11px;
    color: var(--trade5th);
  }

  .top-title-orderlist-second {
    width: 33%;
    text-align: center;
    font-size: 11px;
    color: var(--trade5th);
  }

  .top-title-orderlist-third {
    width: 30%;
    text-align: right;
    font-size: 11px;
    color: var(--trade5th);
  }

  .last-price-orderlisl {
    font-size: 13px;
    //   font-weight: bold;
    background-color: var(--tradebg);
    color: var(--tradegreen);
    height: 30px;
    border-radius: 25px;
    line-height: 40px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
  }

  .last-price-usd-orderlist {
    font-size: 12px;
    color: var(--trade5th);
    padding-left: 20px;
    font-weight: normal;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: var(--trade4th);
  }
}

.bottom-containerD {
  max-width: 1050px !important;
  width: 100%;
}

/////pair lists////
.pairlist-mainD {
  // margin-left: 160px;
  // height: 200px;
  width: 100%;
  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary {
    color: var(--trade4th);
  }

  .Mui-selected {
    color: var(--orange) !important;
  }

  .order-price-in-orderlist {
    color: var(--tradered);
    font-size: 10px;
    text-align: left;
    width: 33%;
    padding-bottom: 2px;
  }

  .order-amount-in-orderlist {
    color: var(--trade5th);
    font-size: 10px;
    text-align: right;
    width: 33%;
    padding-bottom: 2px;
  }

  .order-total-in-orderlist {
    color: var(--trade5th);
    font-size: 10px;
    text-align: right;
    width: 33%;
    padding-bottom: 2px;
  }

  .top-title-orderlist-first {
    width: 33%;
    text-align: left;
    font-size: 10px;
    color: var(--trade5th);
  }

  .top-title-orderlist-second {
    width: 33%;
    text-align: right;
    font-size: 10px;
    color: var(--trade5th);
  }

  .top-title-orderlist-third {
    width: 33%;
    text-align: right;
    font-size: 10px;
    padding-left: 15px;
    color: var(--trade5th);
  }

  .last-price-orderlisl {
    font-size: 11px;
    background-color: var(--tradebg);
    color: var(--tradegreen);
    height: 28px;
    border-radius: 25px;
    line-height: 25px;
  }

  .last-price-usd-orderlist {
    font-size: 11px;
    color: var(--trade5th);
    padding-left: 10px;
    font-weight: normal;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: var(--trade4th);
  }
  .pair-list {
    height: 225px;
  }

  .fav-pair-list {
    height: 225px;
  }
}

///// order history

.orderhistory-mainD {
  background-color: var(--trade2nd);
  height: 225px;

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: var(--trade4th);
  }

  .top-title-orderhistory-first {
    width: 33%;
    text-align: left;
    font-size: 11px;
    padding-top: 10px;
    color: var(--trade5th);
  }

  .top-title-orderhistory-second {
    width: 33%;
    text-align: right;
    font-size: 11px;
    padding: 10px;
    color: var(--trade5th);
  }

  .top-title-orderhistory-third {
    width: 33%;
    text-align: right;
    font-size: 11px;
    padding: 10px;
    color: var(--trade5th);
  }

  .order-price-in-orderhistory {
    font-size: 11px;
    font-weight: 600;
    padding-left: 10px;
    padding-bottom: 2px;
    color: var(--tradegreen);
    text-align: left;
  }

  .order-amount-in-orderhistory {
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 2px;
    color: var(--trade5th);
    text-align: right;
  }

  .order-time-in-orderhistory {
    font-size: 11px;
    font-weight: 600;
    padding-right: 10px;
    padding-bottom: 2px;
    color: var(--trade5th);
  }

  .openorder-main {
    width: auto;
  }
}

.buy-sell-module-mainC {
  .buy-sell-moduleB {
    .limit-buy-mainB {
      .available-sectionB {
        font-size: 12px !important;
      }
    }
  }
}

.available-sectionC {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  color: var(--trade5th);
  font-size: 12px;
}
.buy-sell-moduleC {
  width: 100%;
  height: 300px;
  padding: 0 !important;
  justify-content: flex-end;
}

.star-icon {
  font-size: 18px !important;
}

.MuiTableCell-root {
  background-color: var(--bg-colorone) !important;
}

.market-total-value {
  color: var(--text-colorone);
  font-size: 13px;
}

.btn-market-sellB {
  width: 100%;
  height: 35px;
  line-height: 10px;
  background-color: var(--tradered) !important;
  border: 0;
  margin-top: 40px;
}

.btn-market-buyB {
  width: 100%;
  height: 35px;
  line-height: 10px;
  background-color: var(--tradegreen) !important;
  border: 0;
  margin-top: 40px;
}

.btn-market-sellC {
  width: 100%;
  height: 35px;
  line-height: 10px;
  background-color: var(--tradered) !important;
  border: 0;
  margin-top: 40px;
}

.btn-market-buyC {
  width: 100%;
  height: 35px;
  line-height: 10px;
  background-color: var(--tradegreen) !important;
  border: 0;
  margin-top: 40px;
}

.last-price-orderlislC {
  font-size: 12px;
  font-weight: bold;
  background-color: var(--tradebg);
  color: var(--tradegreen);
  height: 40px;
  border-radius: 25px;
  line-height: 40px;
}

.buy-sell-module-mainD {
  .input-group-text {
    display: flex;
    align-items: center;
    padding-right: 2px;
    padding-left: 2px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    width: 50px;
    height: 30px;
    color: var(--trade4th);
    text-align: center;
    white-space: nowrap;
    background-color: var(--trade2nd);
    border: 0px solid #ced4da;
    border-bottom: 1px solid var(--tradepair);
    border-radius: 0;
  }

  .form-control {
    display: block;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--trade5th);
    background-color: var(--trade2nd);
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-bottom: 1px solid var(--tradepair);
    appearance: none;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .slider-trade {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .btn-limit-sell {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: var(--tradered);
    border: 0;
    margin-top: 39px;
  }

  .btn-limit-buy {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: var(--tradegreen);
    border: 0;
    margin-top: 39px;
  }

  .btn-market-sell {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: var(--tradered);
    border: 0;
    margin-top: 73px;
  }

  .btn-market-buy {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: var(--tradegreen);
    border: 0;
    margin-top: 73px;
  }

  .btn-stoplimit-sell {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: var(--tradered);
    border: 0;
    margin-top: 5px;
  }

  .btn-stoplimit-buy {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: var(--tradegreen);
    border: 0;
    margin-top: 5px;
  }
}

.buy-sell-module-mainD {
  // width: 510px;
  min-height: 374px;
  padding: 0 !important;
  // justify-content: center;
  margin: 0 auto;
  // margin-right: auto;

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary {
    color: var(--trade4th);
  }

  .Mui-selected {
    color: var(--orange) !important;
  }
}

.buy-sell-moduleB {
  width: 100%;
  height: 300px;
  padding: 0 !important;
  justify-content: flex-end;
}

.buy-side-moduleB {
  width: 45%;
  // padding-left: 1px;
  // padding-right: 1px;
}

.sell-side-moduleB {
  width: 45%;
  // padding-left: 1px;
  // padding-right: 1px;
}

/////pair lists D////
.pairlist-mainD {
  width: 101%;
  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary {
    color: var(--trade4th);
  }

  .Mui-selected {
    color: var(--orange) !important;
  }

  .order-price-in-orderlist {
    color: var(--tradered);
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    width: 33%;
    padding-bottom: 2px;
  }

  .order-amount-in-orderlist {
    color: var(--trade5th);
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    width: 33%;
    padding-bottom: 2px;
  }

  .order-total-in-orderlist {
    color: var(--trade5th);
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    width: 33%;
    padding-bottom: 2px;
  }

  .top-title-orderlist-first {
    width: 33%;
    text-align: left;
    font-size: 13px;
    color: var(--trade5th);
  }

  .top-title-orderlist-second {
    width: 33%;
    text-align: right;
    font-size: 13px;
    color: var(--trade5th);
    padding-right: 20px;
  }

  .top-title-orderlist-third {
    width: 33%;
    text-align: right;
    font-size: 13px;
    padding-right: 25px;
    color: var(--trade5th);
  }

  .last-price-orderlisl {
    font-size: 16px;
    font-weight: bold;
    background-color: var(--tradebg);
    color: var(--tradegreen);
    height: 40px;
    border-radius: 25px;
    line-height: 40px;
  }

  .last-price-usd-orderlist {
    font-size: 15px;
    color: var(--trade5th);
    padding-left: 25px;
    font-weight: normal;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: var(--trade4th);
  }
  .pair-list {
    height: 200px;
  }

  .fav-pair-list {
    height: 200px;
  }
}

///// order history D

.orderhistory-mainD {
  width: 100%;
  background-color: var(--trade2nd);
  height: 319px;
  padding: 0 !important;
  // margin-left: 30px;
  // margin-top: 8px;

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: var(--trade4th);
  }

  .orderhistory-module {
    margin-right: 0px;
    padding: 0;
  }

  .top-title-orderhistory-first {
    width: 33%;
    text-align: left;
    font-size: 13px;
    padding-top: 10px;
    color: var(--trade5th);
    padding-left: 14px;
  }

  .top-title-orderhistory-second {
    width: 33%;
    text-align: center;
    font-size: 13px;
    padding: 10px;
    color: var(--trade5th);
  }

  .top-title-orderhistory-third {
    width: 33%;
    text-align: right;
    font-size: 13px;
    padding: 10px;
    color: var(--trade5th);
  }

  .order-price-in-orderhistory {
    font-size: 13px;
    font-weight: 600;
    padding-left: 25px;
    padding-bottom: 2px;
    color: var(--tradegreen);
    text-align: left;
  }

  .order-amount-in-orderhistory {
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 2px;
    color: var(--trade5th);
    text-align: right;
  }

  .order-time-in-orderhistory {
    font-size: 13px;
    font-weight: 600;
    padding-right: 10px;
    padding-bottom: 2px;
    color: var(--trade5th);
  }
}

/// order list ///
.orderlist-mainD {
  width: 100%;
  padding: 0 !important;
  margin-left: 10px;
  .Mui-selected {
    color: var(--orange) !important;
  }

  .order-price-in-orderlist {
    color: var(--tradered);
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    width: 33%;
    padding-bottom: 2px;
    // padding-left: 20px !important;
  }

  .order-amount-in-orderlist {
    color: var(--trade5th);
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    width: 33%;
    padding-bottom: 2px;
  }

  .order-total-in-orderlist {
    color: var(--trade5th);
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    width: 33%;
    padding-bottom: 2px;
    padding-right: 15px;
  }

  .top-title-orderlist-first {
    width: 33%;
    text-align: left;
    font-size: 13px;
    color: var(--trade5th);
    padding-left: 5px;
  }

  .top-title-orderlist-second {
    width: 33%;
    text-align: right;
    font-size: 13px;
    color: var(--trade5th);
  }

  .top-title-orderlist-third {
    width: 33%;
    text-align: right;
    font-size: 13px;
    color: var(--trade5th);
    padding-right: 24px !important;
  }

  .last-price-orderlisl {
    font-size: 16px;
    //   font-weight: bold;
    background-color: var(--tradebg);
    color: var(--tradegreen);
    height: 30px;
    border-radius: 25px;
    line-height: 40px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
  }

  .last-price-usd-orderlist {
    font-size: 15px;
    color: var(--trade5th);
    padding-left: 25px;
    font-weight: normal;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: var(--trade4th);
  }
}

.container-openorderD {
  background-color: var(--tradebg);
  height: 400px;
  // width: 819px !important;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
  overflow-x: scroll;
  min-width: 470px;
  .openorder-main {
    width: auto;
    .MuiButtonBase-root,
    .MuiTab-root,
    .MuiTab-textColorPrimary {
      color: var(--trade4th);
    }

    .Mui-selected {
      color: var(--orange) !important;
    }
  }
}

.textdanger {
  color: var(--tradered) !important;
}

.textsuccess {
  color: var(--tradegreen) !important;
}
