.easy-main {
  background-color: var(--bg-colortwo);
  min-height: 90vh;
}

.easy-main-m {
  background-color: var(--bg-colorone);
  min-height: 130vh;
}

.easycard-page {
  background: url("/assets/images/bgeasycode.png") no-repeat;
  margin-top: -30px;
  margin-bottom: -30px;
  padding: 20px !important;
  color: var(--text-colorone);

  @media (min-width: 1200px) {
    min-height: 70vh;
  }

  @media (max-width: 767px) {
    margin-top: 100px;
  }

  .easycard-cnt {
    //  background: #fff;
    border-radius: 5px;

    h4 {
      font-size: 30px;
      color: var(--text-colorone);
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 16px;
      color: var(--text-colorone);
    }

    .form-group {
      .form-control {
        border: 1px solid var(--bluecolor);
        background-color: var(--bg-colortwo);
        height: 45px;
        border-radius: 5px;
        font-size: 18px;
      }

      textarea.form-control {
        height: 150px;
      }

      p {
        font-size: 16px;
        color: var(--text-colorone);

        a {
          color: var(--bluecolor);
          text-decoration: none;
        }
      }
    }
  }

  .easy-card-div {
    //  background: #fff;
    height: 100%;
    border-radius: 5px;

    @media (max-width: 575px) {
      padding: 1rem !important;
    }

    h1 {
      font-size: 35px;
      //      font-style: italic;
      font-weight: bold;
      color: var(--text-colorone);
    }

    img.easyimg {
      width: auto;
      height: 400px;

      @media (max-width: 1000px) {
        height: 250px;
      }
    }

    img {
      width: auto;
      height: 400px;

      @media (max-width: 1000px) {
        height: 250px;
      }
    }

    .cnt-div {
      margin-left: 1rem;

      @media (max-width: 767px) {
        margin-left: 0rem;
      }

      h4 {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: var(--text-colorone);

        @media (max-width: 7675px) {
          margin-top: 1rem;
        }
      }

      p {
        font-size: 18px;
        color: var(--text-colorone);
      }

      label {
        font-size: 18px;
        color: var(--text-colorone);

        a {
          color: var(--text-colorone);
          text-decoration: none;
        }
      }

      .form-control {
        height: 40px;
        border: 1px solid #22577a;
        border-radius: 5px !important;
      }

      .cont-btn {
        background: var(--btn-one);
        margin-left: 0.5rem;
        color: var(--btn-one-hover);
        font-size: 18px;
        font-weight: 500;
        width: 150px;
        border-radius: 5px;
      }

      .cont-btn:hover {
        background: var(--btn-one-hover);
        color: var(--btn-one);
        transition: all 0.5s;
        border: 1px var(--btn-one) solid;
      }
    }

    .title-div {
      @media (max-width: 767px) {
        display: block !important;
      }
    }
  }
}

.easycard-page-m {
  background: url("/assets/images/bgeasycode.png") no-repeat;
  background-color: var(--bg-colorone);
  margin-top: 40px;
  padding: 10px !important;
  color: var(--text-colorone);

  .easycard-cnt {
    //  background: #fff;
    border-radius: 5px;

    h4 {
      font-size: 30px;
      color: var(--text-colorone);
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 16px;
      color: var(--text-colorone);
    }

    .form-group {
      .form-control {
        border: 1px solid var(--bluecolor);
        background-color: var(--bg-colortwo);
        height: 45px;
        border-radius: 5px;
        font-size: 18px;
      }

      textarea.form-control {
        height: 150px;
      }

      p {
        font-size: 16px;
        color: var(--text-colorone);

        a {
          color: var(--bluecolor);
          text-decoration: none;
        }
      }
    }
  }

  .easy-card-div {
    //  background: #fff;
    height: 100%;
    border-radius: 5px;

    @media (max-width: 575px) {
      padding: 1rem !important;
    }

    h1 {
      font-size: 35px;
      //      font-style: italic;
      font-weight: bold;
      color: var(--text-colorone);
    }

    img.easyimg {
      width: auto;
      height: 400px;

      @media (max-width: 1000px) {
        height: 250px;
      }
    }

    img {
      width: auto;
      height: 400px;

      @media (max-width: 1000px) {
        height: 250px;
      }
    }

    .cnt-div {
      margin-left: 1rem;

      @media (max-width: 767px) {
        margin-left: 0rem;
      }

      h4 {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: var(--text-colorone);

        @media (max-width: 7675px) {
          margin-top: 1rem;
        }
      }

      p {
        font-size: 18px;
        color: var(--text-colorone);
      }

      label {
        font-size: 18px;
        color: var(--text-colorone);

        a {
          color: var(--text-colorone);
          text-decoration: none;
        }
      }

      .form-control {
        height: 40px;
        border: 1px solid #22577a;
        border-radius: 5px !important;
      }

      .cont-btn {
        background: var(--btn-one);
        margin-left: 0.5rem;
        color: var(--btn-one-hover);
        font-size: 18px;
        font-weight: 500;
        width: 150px;
        border-radius: 5px;
      }

      .cont-btn:hover {
        background: var(--btn-one-hover);
        color: var(--btn-one);
        transition: all 0.5s;
        border: 1px var(--btn-one) solid;
      }
    }

    .title-div {
      @media (max-width: 767px) {
        display: block !important;
      }
    }
  }
}

.easy-btn {
  background: var(--btn-one) !important;
  color: var(--btn-one-hover) !important;
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid var(--btn-one) !important;
}

.easy-btn:hover {
  background: var(--btn-one-hover) !important;
  color: var(--btn-one) !important;
  transition: all 0.5s;
}