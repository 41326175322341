.broker-reg-page {
  margin-top: -30px;
  overflow: auto;
  margin-bottom: 0px;
  min-height: 100vh;
  background-color: var(--bg-colorone);
  .form-control {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  h3 {
    font-size: 24px;
    color: var(--text-colorone);
    font-weight: 600;
    margin-top: 50px;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 18px;
    color: var(--text-colorone);
    margin-bottom: 1.5rem;
    font-weight: 100;
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }
  .img-fluid {
    height: 150px;
    width: auto;
  }
  .otc-div {
    .otc-det-div {
      margin-bottom: 10px;
      .imgdiv {
        min-height: 50px;
      }
      p {
        text-align: center;
        font-size: 14px;
        color: var(--text-colorone);
        font-weight: 200;
        margin-top: 1rem;
        min-height: 150px;
        @media (max-width: 575px) {
          font-size: 12px;
        }
      }
    }
  }
  .support-otc-div {
    background: var(--bg-colorone);
    h5 {
      font-size: 26px;
      color: var(--text-colorone);
      font-weight: 400;
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
    p {
      font-size: 18px;
      color: var(--text-colorone);
      margin-bottom: 1.5rem;
      font-weight: 300;
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
    ul li {
      font-size: 18px;
      color: var(--text-colorone);
    }
    label {
      color: var(--text-colorone);
    }
    .form-control {
      height: 40px;
      border-radius: 5px;
    }
    textarea.form-control {
      height: auto;
    }
  }
}
