.tokenlisting-main {
  background-color: var(--bg-colorone);
  margin-top: -30px;
  overflow: auto;
}

.tokenlisting-title {
  margin-top: 40px;

  color: var(--text-colorone);
}

.tokenlisting-form {
  background-color: var(--bg-colorone);
  border-radius: 15px;
  background-color: var(--bg-colorone);
  color: var(--text-colorone);
  margin-top: 50px;
  margin-bottom: 50px;

  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
    ) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
