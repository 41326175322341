.home-page {
  background-color: var(--bg-colorone);
}

.home-body {
  margin-top: -20px;
  background-color: var(--bg-colorone);

  @media (max-width: 769px) {
    margin-top: -390px;
  }
}

.home-top-section {
  height: 100vh;
  background-image: var(--bg-homebg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  @media (max-width: 769px) {
    height: 1800px;
  }

  .top-phone-bg {
    background-image: var(--bg-homemobile);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 500px;
    max-height: 510px;
    align-items: center;
    margin-top: 50px;
    animation: animatedBackground 1.5s linear alternate;

    .inside-mobile {
      margin-top: 150px;
    }

    .btn-mobile-top {
      width: 165px;
      height: 70px;
      background-color: var(--bg-colortwo);
      text-align: center;

      .lottie-icon {
        width: 25px;
        height: 25px;
      }

      a {
        text-decoration: none;
        color: var(--text-colorone);
      }
    }

    .btn-mobile-register {
      width: 165px;
      height: 40px;
      background-color: var(--bluecolor);
      text-align: center;

      .lottie-icon {
        width: 25px;
        height: 25px;
      }

      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .top-right {
    height: 500px;
    color: var(--text-colorone);
  }
}

.btn-phone {
  max-width: 120px;
}

.move-left-phone {
  margin-left: -26px;
}

.btn-top-insidephone {
  background-color: var(--bg-colorthree) !important;
  border: 0px;
  color: var(--text-colorone) !important;
  transition: all 0.5s;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  height: 69px;
  width: 151px;
  font-weight: 600;
  font-size: 12px !important;

  .fa {
    color: #919191;
  }
}

.btn-top-insidephone2 {
  background-color: var(--bg-colorthree) !important;
  border: 0px;
  color: var(--text-colorone) !important;
  display: flex;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.5s;
  height: 50px;
  width: 151px;
  font-weight: 600;

  .fa {
    color: #919191;
  }
}

.btn-top-insidephone2:hover {
  background-color: var(--bg-colortwo) !important;
  color: var(--text-colorone) !important;
}

.btn-top-insidephone:hover {
  background-color: var(--bg-colortwo) !important;
  color: var(--text-colorone) !important;
}

.btn-top-insidephone-reg {
  background-color: var(--bluecolor) !important;
  border: 0px;
  color: var(--text-colorthree) !important;
  display: flex;
  border-radius: 8px;
  padding: 2px;
  transition: all 0.5s;
  height: 50px;
  width: 153px;
  font-size: 12px !important;

  .fa {
    color: #919191;
  }
}

.btn-top-insidephone-reg:hover {
  background-color: var(--bg-colorthree) !important;
  color: var(--text-colorone) !important;
}

.registericon2 {
  width: 40px !important;
  padding: 0 !important;
}

.gIconDiv {
  width: 40px !important;
  padding: 0 !important;
  font-weight: 600;
}

.apkIconDiv {
  width: 50px !important;
  padding: 0 !important;
}

.apktext {
  padding-top: 7px;
  padding-left: 5px;
  font-size: 12px !important;
}

.appletext {
  padding-top: 0px;
  margin-top: -1px;
}

.regtext {
  padding-top: 5px;
}

.market-home-page {
  min-height: 60vh;
  background-color: var(--bg-colorone);
}

.table-main {
  background-color: var(--bg-colortwo) !important;
}

.easy-guarantee {
  height: 60vh;
  background-color: var(--bg-colorthree) !important;

  h1 {
    margin-top: 70px;
    font-style: italic;
    font-weight: 700;
    color: #17b13c;
  }

  h3 {
    font-family: "Major Mono Display";
    color: var(--text-colorone);
  }

  p {
    font-size: 18px;
  }
}

.guarantee-home {
  background-color: var(--bg-colorthree) !important;
  color: #fff;
  min-height: 60vh;
}

.guarantee-btn {
  width: 160px;
  height: 60px;
  font-size: 16px;
  background: var(--btn-one) !important;
  border: 1px solid var(--btn-one) !important;
  border-radius: 5px;
  color: var(--btn-one-hover) !important;
  text-align: center;
  margin-right: 5px;

  &:hover {
    background-color: var(--btn-one-hover) !important;
    color: var(--btn-one) !important;
    transition: all 0.5s;
  }
}

.easyguarantee-title {
  font-style: italic;
  font-weight: bold;
  color: #17b13c;
}

.guarantee-subtitle {
  // font-family: "Major Mono Display" !important;
  color: var(--text-colorone);
}

.guarantee-content {
  color: var(--text-colorone);
}

.bg-slide {
  background: var(--bg-colorone);

  h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--text-colorone);
  }

  h3 {
    font-size: 36px;
    font-weight: 700;
    color: var(--text-colorone);
    font-family: "Titillium web", "PT Sans", "Almarai", cursive;
    letter-spacing: 2px;
  }

  p {
    font-size: 16px;
    color: var(--text-colorone) !important;
  }
}

.bg-easy {
  background: var(--bg-colorone);
  text-align: center;
  padding-bottom: 0;
  min-height: 60vh;

  h6 {
    color: var(--text-colorone);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 5px;

    h3 {
      color: var(--text-colorone);
    }

    p {
      color: var(--text-colorone) !important;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      padding-bottom: 5px;
    }
  }
}

.easy-home {
  p {
    color: var(--text-colorone);
  }
}

.button-easy {
  background-color: var(--btn-one);
  border: 1px var(--btn-one) solid;
  color: var(--btn-one-hover);
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  position: relative;
  margin-top: 20px;
  border-radius: 5px;
}

a.button-easy:hover {
  background-color: var(--btn-one-hover);
  color: var(--btn-one);
  transition: all 0.5s;
  border: 1px var(--btn-one) solid;
}

.easy-btn-buy {
  text-align: center;
  padding: 5px;
  width: 150px;
  border-radius: 15px;
  background-color: var(--bg-colortwo);
  margin-left: auto;
  margin-right: auto;
  color: var(--text-colorone);
  margin-bottom: 10px;
  margin-top: 30px;

  &:hover {
    background-color: var(--bg-colorfourth);
    color: var(--btn-one-hover);
  }
}

.bg-yellow {
  background-color: var(--bg-colorfourth);
  background-image: url("../../assets/images/bglogo.svg");
  background-blend-mode: color-dodge;
  background-repeat: no-repeat;
  padding-bottom: 6rem !important;
  color: var(--text-colorfifth);

  @media (min-width: 1200px) {
    min-height: 80vh;
  }
}

.hlapp {
  h1 {
    margin-top: 100px;
    color: var(--text-colorfifth);
    font-size: 26px !important;
  }
}

.ulapp {
  color: var(--text-colorfifth);
  font-size: 16px;
}

.btn-app {
  width: 150px;
  height: 60px;
  font-size: 16px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #082032;
  text-align: center;
  margin-right: 5px;

  &:hover {
    background-color: rgba(8, 32, 50, 0.2);
    color: #ffe180;
    transition: all 0.5s;
  }
}

.appicon {
  width: 30px;
}

.otherparties {
  background-color: white;
  align-content: center;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  height: 420px;
}

.header-mobile {
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 47px;
  width: 100%;
  background-color: var(--bg-colortwo);
}

.menu-btn-mobile {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;

  .menu-icon {
    color: var(--text-colorone) !important;
  }
}

.home-main-mobile {
  background-color: var(--bg-colorone);
  min-height: 120vh;
  min-width: 300px !important;
  overflow-x: scroll;
}

.btn-places-mobile {
  margin-top: 20px;
  background-color: var(--bg-colortwo);
  height: 205px;
  align-content: center;
}

.svg-icon {
  height: 25px;

  .cls-1 {
    fill: var(--text-colorone);
  }

  .cls-2 {
    fill: var(--orange);
    height: 25px;
  }
}

.btns-home-mobile-places {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
  color: var(--text-colorone) !important;
  margin: 0 !important;
  font-size: 11px !important;

  &:hover {
    background-color: var(--text-colortwo) !important;
  }
}

.btn-home-log-m {
  margin-top: 15px;
  background-color: var(--orange) !important;
  min-width: 130px !important;
  border: none !important;
  min-height: 40px !important;
  height: 40px !important;
}

.carousel-price-mobile {
  height: 200px;
  margin-bottom: 50px;
}

.carousel-items {
  height: 190px;
  width: 240px !important;
  background-color: var(--bg-colorthree);
  border-radius: 15px;

}

.carousel-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* Adjust background color for visibility */
  color: var(--text-colorone);
  font-size: 12px;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
}

// .nav-link {
//   margin-top: 10px;
// }

.market-table-btn-mobile {
  height: 35px !important;
  background-color: var(--bg-colorone) !important;
  border: none !important;
  color: var(--text-colorone) !important;
  font-size: 13px !important;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  background-color: var(--bg-colortwo);
  height: 50px;
  width: 100%;
  z-index: 1000;
}

.btn-navigation-m {
  background-color: transparent !important;
  font-size: 14px !important;

  .btn-navigation-m-icon {
    font-size: 24px !important;
    color: var(--text-colorone) !important;
    margin-top: 5px !important;
  }
}

.mobile-menu {
  background-color: var(--bg-colorone);
  min-height: 100vh;
}

.back-btn-m {
  font-size: 18px !important;
  margin-top: 13px;
  background-color: transparent !important;
  color: var(--text-colorone) !important;
  margin: 0 !important;
  min-width: 20px !important;
  margin-top: 6px !important;
}


hr.MuiDivider-root.MuiDivider-fullWidth.divider-menu.MuiDivider-root {
  color: var(--text-colorone) !important;
  border-color: var(--text-colorone) !important;
  margin-top: 10px;
  border-width: 2px;
}

.each-icon-menu-m {
  color: var(--orange) !important;
}

.each-text-menu-m {
  color: var(--text-colorone) !important;
  font-size: 14px;
}

.menu-btn-m {
  font-size: 14px !important;
  background-color: transparent !important;
  color: var(--text-colorone) !important;
  margin: 0 !important;
  text-decoration: none;
  text-transform: none !important;
  min-width: 300px !important;
  text-align: start !important;
}