.coininfo-main {
  background-color: var(--bg-colorone);
  margin-top: -40px;
  overflow: auto;

  .coininfo-module {
    margin-top: 60px;
    margin-bottom: 80px;
    color: var(--text-colorone);
    background-color: var(--bg-colortwo);
    padding: 10px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    overflow-wrap: break-word;

    a {
      color: var(--orange);
    }
  }
}
