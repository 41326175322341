.navbar,
.navbar-default,
.navbar-header,
.bg-light {
  background-color: var(--navbar);
  background-image: none !important;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);

  .nav-link {
    color: var(--text-colorone) !important;
  }

  .nav-link:hover {
    color: var(--orange) !important;
  }

  .nav-link.active {
    color: var(--orange) !important;
  }

  .nav-link-sec {
    color: var(--text-colorone) !important;
  }

  .nav-link-sec:hover {
    color: var(--orange) !important;
  }

  .nav-link-sec.active {
    color: var(--orange) !important;
  }

  .dropdown-menu {
    background-color: var(--bg-dropdown);
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    min-width: 200px;
  }

  .navbar-main {
    height: 60px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    font-weight: 700 !important;
    font-family: "Titillium Web";

    .logo {
      padding-top: 4px;
      padding-left: 10px;
      width: auto;
      height: 40px;
    }
  }

  .icon-navbar-main {
    margin-right: 30px;
    margin-top: -5px;

    .icon-navbar-ui {
      width: 30px !important;
      margin-right: 15px;
      font-size: 30px !important;
    }

    .icon-navbar-ui:hover {
      background-color: transparent !important;
    }
  }
}

.icon-navbar-ui-profile {
  font-size: 30px !important;
  margin-right: -27px;
}

.icon-navbar-ui-profile:hover {
  background-color: transparent !important;
  color: var(--orange) !important;
}

.MuiSvgIcon-root {
  font-size: 30px !important;
}

.MuiSvgIcon-root:hover {
  background-color: transparent !important;
  color: var(--orange) !important;
}

.offcanvas-body {
  background-color: var(--navbar) !important;
  color: var(--text-colorone);
  padding-left: 20px !important;

  // padding-right: 60px !important;
  .dropdown-menu.show {
    background-color: var(--bg-colortwo);
    border: 0px;
    color: var(--text-colorone);
    padding-left: 20px !important;
  }

  .nav-link.active {
    color: var(--orange) !important;
  }
}

.offcanvas-title {
  color: var(--text-colorone);
}

.offcanvas-header {
  background-color: var(--bg-colorone) !important;
}

.btn-close {
  color: var(--text-colorone) !important;
  background: url(../../assets/images/close.svg) !important;
  height: 30px !important;
  width: 30px !important;
}

.navbar-toggler-icon {
  background-image: url(../../assets/images/menu.svg) !important;
  outline: none !important;
}

.logohere {
  background-image: var(--logo);
  background-position: center;
  background-size: 180px 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 200px;
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
}

.title-logo {
  color: var(--text-colorone);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {

  justify-content: start !important;
}