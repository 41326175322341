.privacy-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .privacy-module {
    margin-top: 60px;
    color: var(--text-colorone);
    text-align: justify;
    margin-bottom: 60px;
  }
}

.terms-module-m {
  margin-top: 60px;
  color: var(--text-colorone);
  text-align: justify;
  margin-bottom: 60px;

  h1 {
    font-size: 21px !important;
    margin-top: 30px;

  }

  h2 {
    font-size: 19px !important;
    margin-top: 30px;
  }

  p {
    font-size: 14px !important;
  }
}