.withdraw-main {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -30px;
  overflow: auto;
  .withdraw {
    min-height: 80vh;
    margin-top: 60px;
  }

  .withdraw-module {
    color: var(--text-colorone);
    min-height: 600px;
    width: 700px;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }

  .withdraw-texts {
    color: var(--text-colorone) !important;
  }
  .MuiInputBase-root,
  .MuiSelect-icon {
    color: var(--text-colorone) !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--text-colorone) !important;
  }
  .btn-primary {
    background-color: var(--bluecolor);
    border-color: var(--bluecolor);
    color: var(--text-colorthree);

    &:hover {
      background-color: var(--text-colorthree);
      border-color: var(--bluecolor);
      color: var(--bluecolor);
    }
  }

  .withdraw-bottom {
    h6 {
      font-size: 13px !important;
    }
  }
}
.attention-withdraw {
  font-size: 13px !important;
  text-align: justify;
  color: var(--text-colorone) !important;
  margin-bottom: 5px;

  span {
    color: var(--tradered) !important;
  }
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around !important;
  padding: 5px !important;
  background-color: var(--bg-colorone) !important;
  border-top: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

  .confirm-withdraw {
    background-color: var(--tradegreen);
    border-color: var(--tradegreen);
    color: var(--text-colorfifth);

    &:hover {
      background-color: var(--text-colorfifth);
      border-color: var(--text-colorfifth);
      color: var(--tradegreen);
    }
  }

  .cancel-withdraw {
    background-color: var(--tradered);
    border-color: var(--tradered);
    color: var(--text-colorfifth);

    &:hover {
      background-color: var(--text-colorfifth);
      border-color: var(--text-colorfifth);
      color: var(--tradered);
    }
  }
}
.withdraw-texts {
  color: var(--text-colorone) !important;
}
.MuiInputBase-root,
.MuiSelect-icon {
  color: var(--text-colorone) !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: var(--text-colorone) !important;
}

.confirmation-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .confirmation-module {
    min-height: 500px;
    min-width: 350px;
    width: 500px;
    background-color: var(--bg-colortwo);
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    margin-top: 100px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

    h5 {
      padding-top: 20px;
      margin-left: 20px;
      color: var(--text-colorone);
    }

    h6 {
      padding-top: 20px;
      margin-left: 20px;
      color: var(--text-colorone);
    }

    .confirm-loader {
      width: 100%;
      text-align: center;
      margin-top: 150px;
    }
  }
}

.when-error {
  height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;
  .error-module {
    margin-top: 100px;
    min-height: 300px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

    .attention-withdraw {
      text-align: center;
      padding-top: 50px;
      font-size: 20px !important;
    }
  }

  .error-btn {
    padding-top: 50px;
    text-align: center;

    .btn-withdraw-error {
      background: var(--bg-colorone);
      margin-left: 0.5rem;
      color: var(--text-colorone);
      font-size: 18px;
      font-weight: 500;
      width: 150px;
      border-radius: 5px;
      border: 1px var(--bg-colortwo) solid;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }
    .btn-withdraw-error:hover {
      background: var(--btn-one-hover);
      color: var(--btn-one);
      transition: all 0.5s;
      border: 1px var(--btn-one) solid;
    }
  }
}
