.footer-main {
  height: 350px;
  background-color: var(--bg-colortwo);
  color: var(--text-colorone);
  font-family: "Titillium Web";
  -webkit-box-shadow: 0px -7px 24px 8px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px -7px 24px 8px rgba(0, 0, 0, 0.28);
  box-shadow: 0px -7px 24px 8px rgba(0, 0, 0, 0.28);

  @media (max-width: 1200px) {
    height: 850px;
  }

  h3 {
    font-size: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0px;
    font-size: 18px !important;

    li {
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    a {
      text-decoration: none;
      color: var(--text-colorone);
      padding-left: 0px;

      &:hover {
        color: var(--orange) !important;
        // background-color: var(--orangebg) !important;
      }

      &:active {
        color: var(--orange) !important;
        background-color: var(--orangebg) !important;
      }
    }
  }

  .accordion .accordion-button {
    background-color: var(--bg-colortwo);
    color: var(--text-colorone);
  }

  .accordion-item:first-of-type .accordion-button {
    background-color: var(--bg-colortwo);
    color: var(--text-colorone);
  }

  .accordion-body {
    background-color: var(--bg-colortwo);
  }

  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}

.logoherefooter {
  background-image: var(--logo);
  background-position: center;
  background-size: 180px 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 200px;
  margin-top: 25px !important;
}

.logofootermobile {
  background-image: var(--logo);
  background-position: center;
  background-size: 180px 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 200px;
  margin-top: 25px !important;
}

.footer-mobile {
  margin-top: -50px !important;
  overflow: auto;
}


.trading-footer {
  text-decoration: none;
  color: var(--text-colorone);
  font-weight: 600;
}