.faq-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  margin-top: -60px;
  overflow: auto;
  .faq-module {
    margin-bottom: 50px;
    background-color: var(--bg-colorone);
    min-height: 80vh;
    color: var(--text-colorone) !important;
    margin-top: 95px;

    .accordion-button {
      background-color: var(--bg-colorone) !important;
      color: var(--text-colorone) !important;
      border: 2px solid var(--bg-colorone) !important;
    }

    .accordion-body {
      background-color: var(--bg-colorone) !important;
      color: var(--text-colorone) !important;
      border: 2px solid var(--bg-colorone) !important;
    }

    .accordion {
      --bs-accordion-btn-icon: none;
      --bs-accordion-btn-active-icon: none;
      border: 2px solid var(--bg-colorone) !important;
    }
  }
}
