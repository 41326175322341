.chart-main {
  width: 100vw;
  //   overflow: hidden;
  //   margin-top: -100px;
  //   margin-right: -172px;
  background-color: transparent !important;
}

.chart {
  position: relative;
  opacity: 1;
  width: 100vw;
  margin-right: -50px;
  z-index: -1;
}
