.guarantee-information {
  background-color: var(--bg-colorone);
  margin-top: -30px;
}
.guarantee-information {
  background-color: var(--bg-colorthree);
  padding-top: 50px;
  padding-bottom: 50px;
  color: var(--text-colorone);
}
.guarantee-section {
  background-color: var(--bg-colorone);
  box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.2);
}

.button-easy {
  background-color: #22577a;
  border: 1px #22577a solid;
  color: white;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  position: relative;
  margin-top: 20px;
  border-radius: 5px;
}

a.button-easy:hover {
  background-color: white;
  color: #22577a;
  transition: all 0.5s;
  border: 1px #22577a solid;
}

.font-weight-bold {
  font-weight: 700;
}
