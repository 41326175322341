.theme-selector-pop {
  .form-select {
    outline: none !important;
  }

  .language-icon {
    font-size: 30px !important;
  }

  .language-icon:hover {
    background-color: transparent !important;
    color: var(--orange) !important;
  }
  .MuiPaper-root {
    background-color: var(--bg-colorone) !important;
    color: var(--text-colorone) !important;
  }
}

.MuiPopover-paper {
  background-color: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
}
