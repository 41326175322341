.about-main {
  .videoTag {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -10;
    margin-top: -30px;
  }

  .section1 {
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    margin: 0 auto;
    justify-content: space-between;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 600px) {
    .section1 {
      flex-direction: column;
    }
  }

  .top {
    order: 1;
    width: 100%;
    padding-top: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1s1 {
    font-size: 70px;
  }

  .p1s1 {
    font-size: 26px;
  }
  @media only screen and (max-width: 600px) {
    .h1s1 {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .p1s1 {
      font-size: 14px;
    }
  }

  .banner {
    width: 100%;
    height: 30vh;
    /* background: #22577a; */
    order: 2;
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    text-align: center;
    justify-content: space-around;
  }

  .h1b1 {
    font-size: 30px;
    font-family: "titillium web";
  }
  @media only screen and (max-width: 600px) {
    .h1b1 {
      font-size: 16px;
    }
  }

  .p1b1 {
    font-size: 18px;
  }
  @media only screen and (max-width: 600px) {
    .p1b1 {
      font-size: 14px;
    }
  }

  .section2 {
    height: auto;
    width: 100%;
    text-align: center;
    background: var(--bg-colorone);
    box-sizing: border-box;
    margin-top: 0;
    padding-top: 0;
    color: var(--text-colorone);
  }
  @media only screen and (max-width: 600px) {
    .section2 {
      height: auto;
    }
  }

  .insection2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    padding-top: 60px;
  }
  @media only screen and (max-width: 600px) {
    .insection2 {
      flex-direction: column;
      width: 100%;
    }
  }

  .h1s2 {
    padding-top: 100px;
    font-size: 18px;
    color: var(--text-colorone);
    font-weight: 700;
  }

  .h2s2 {
    color: var(--text-colorone);
    font-size: 22px;
  }

  .h3s2 {
    font-size: 20px;
    color: var(--text-colorone);
  }

  h2 {
    font-size: 20px;
  }

  .i1 {
    flex-basis: 30%;
    padding-bottom: 30px;

    h2 {
      font-size: 18px;
      font-weight: 700;
      color: var(--text-colorone);
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: var(--text-colorone);
    }
  }

  .section3 {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    justify-content: center;
    text-align: center;
    padding: 20px;
    height: 50vh;
  }

  .h1s3 {
    font-size: 14px;
  }

  .h2s3 {
    font-size: 36px;
    font-weight: 700;
  }

  .h3s3 {
    font-size: 16px;
    font-weight: 500px;
  }

  #timeline {
    display: flex;
    background-color: #22577a;
  }
  @media only screen and (max-width: 600px) {
    #timeline {
      flex-direction: column;
    }
  }

  #timeline:hover .tl-item {
    width: 33.3333%;
  }
  @media only screen and (max-width: 600px) {
    #timeline:hover {
      width: 100%;
    }
  }

  .tl-item {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 33.3333%;
    height: 100vh;
    min-height: 600px;
    color: #fff;
    overflow: hidden;
    transition: width 0.5s ease;
  }
  @media only screen and (max-width: 600px) {
    .tl-item {
      width: 100%;
    }
  }

  .tl-item:after,
  .tl-item:before {
    transform: translate3d(0, 0, 0);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .tl-item:after {
    background: #22577a;
    border: 1px white solid;
    opacity: 1;
    transition: opacity 0.5s ease;
    text-decoration: none;
  }

  .tl-item:before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #575757 75%);
    z-index: 1;
    opacity: 0;
    transform: translate3d(0, 0, 0) translateY(50%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .tl-item:hover {
    width: 40% !important;
  }
  @media only screen and (max-width: 600px) {
    .tl-item:hover {
      width: 100% !important;
    }
  }

  .tl-item:hover:after {
    opacity: 0;
  }

  .tl-item:hover:before {
    opacity: 1;
    transform: translate3d(0, 0, 0) translateY(0);
    transition: opacity 1s ease, transform 1s ease 0.25s;
  }

  .tl-item:hover .tl-content {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.75s ease 0.5s;
  }

  .tl-item:hover .tl-bg {
    filter: grayscale(0);
  }

  .tl-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 0 1.618em;
    top: 52%;
    opacity: 0;
    line-height: 30px;
  }

  .tl-content h1 {
    font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial,
      sans-serif;
    text-transform: uppercase;
    color: #1779cf;
    font-size: 1.44rem;
    font-weight: normal;
  }

  .tl-year {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
  }

  .tl-year p {
    font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial,
      sans-serif;
    font-size: 1.728rem;
    line-height: 0;
  }

  .tl-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }
}
