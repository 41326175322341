.assets-main {
  min-height: 80vh;
  background-color: var(--bg-colorone);
  background-image: var(--trans);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -30px;
  overflow: auto;

  .wallets {
    margin-top: 60px;
    // min-height: 70vh;
    min-width: 650px;
    background-color: var(--bg-colortwo);
    margin-bottom: 30px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }
}

.table-head {
  background-color: var(--bg-colorthree);
  color: var(--text-colorone) !important;
  font-size: 18px;
  text-align: end !important;
}

.table-row {
  color: var(--text-colorone) !important;
}

.header-text {
  color: var(--text-colorone);
  font-size: 15px !important;
  text-align: end;
}

.header-text-b {
  color: var(--text-colorone);
  font-size: 15px !important;
  font-weight: bold;
  text-align: end;
}

.divider-text {
  background-color: var(--text-colorone);
  opacity: 0.2;
}

.MuiTabs-indicator {
  display: none;
  background: var(--text-colorone) !important;
}

.Mui-selected {
  color: var(--text-colorone) !important;
}

.MuiTouchRipple-root {
  color: var(--text-colortwo) !important;
}

button.Mui-selected .Mui-root::before {
  background-color: var(--text-colorone);
}

.tab-scroll {
  overflow-y: scroll;
}

.sumofallassets {
  color: var(--text-colorone);
}

.MuiButtonBase-root,
.MuiTab-root {
  color: var(--text-colorone) !important;
}

.Mui-selected {
  color: var(--orange) !important;
}


.assets-main-mobile {
  width: 100%;
  background-color: var(--bg-colorone);
  min-height: 120vh;
  min-width: 300px;
  overflow-x: scroll;
}

.btn-assets-mobile {
  background-color: transparent !important;
  font-size: 10px !important;
}

.btn-assets-mobile-active {
  background-color: var(--orange) !important;
  font-size: 10px !important;
}

.each-asset-mobile {
  background-color: var(--bg-colortwo);
  height: 90px;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 8px;
}