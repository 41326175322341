


.carousel {
    overflow: hidden;
    padding-bottom: 0rem;
    // direction: rtl;
    bottom: 10px;
}

.carousel-caption {
    top: 60%;
    text-align: right;
    color: #22577a;
    background-color: rgba(255, 255, 255, 0.452);

}

.carousel-inner {
    overflow: visible;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
    bottom: 2rem;
}

.carousel-item {
    text-align: center;
}

.carousel-control .glyphicon-chevron-right {
    display: none;
}