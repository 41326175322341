* {
  box-sizing: border-box !important;
}

.easyguarantee-main {
  background-color: var(--bg-colorone) !important;
}

.body-white {
  background-color: var(--bg-colorone) !important;
  position: fixed;
  z-index: -10;
  height: 100vh;
}

.bg-white-eg {
  background-color: var(--bg-colorone) !important;
}

.guarantee-exp {
  min-height: 25vh;
  background-color: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
  margin-top: -170px;
  width: 40%;
  margin-left: 10%;
  margin-right: auto;
  z-index: 1;
  position: relative;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  text-align: justify;
  @media (max-width: 1000px) {
    width: 75%;
  }
}

.section-top-guarantee {
  background: var(--bgcurve) no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: var(--bg-colorone) !important;
  min-height: 90vh;
  align-content: flex-start;
  align-items: center;
  margin-top: -30px !important;
}

@media only screen and (max-width: 1000px) {
  .section-top-guarantee {
    padding-top: 18%;
  }
}

.top-title {
  color: var(--text-colorone) !important;
  text-align: center;
  align-items: center;
}

.h1-title-top {
  font-size: 50px !important;
  font-family: "titillium web";
  font-weight: 700;
  font-style: italic;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
  .h1-title-top {
    font-size: 35px !important;
  }
}

.p-title-top {
  font-size: 24px;
  font-weight: 400;
}

//triple boxes

.triples-main {
  padding: 20px;
  width: 100%;
  min-height: 50vh;
  background-color: var(--bg-colortwo);
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.triples {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: 90%;
  min-height: 70%;
  // align-self: flex-end;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
}

.triples-box {
  text-align: left;
  background-color: var(--bg-colorone) !important;
  min-height: 350px;
  margin: 40px;
  width: 280px;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.53);
  -webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.53);
  text-align: center;
  color: var(--text-colorone) !important;
}

.p-triplebox {
  text-align: justify;
  padding: 18px;
  font-size: 16px;
  color: var(--text-colorone) !important;
}

.guarantee-steps {
  min-height: 50vh;
  background-color: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
}

.h4-steps {
  text-align: center;
  color: var(--text-colorone) !important;
}

.p-steps {
  text-align: center;
  color: var(--text-colorone) !important;
}

.img-steps {
  text-align: center;
}

.companies-main {
  background: var(--egbot) no-repeat center center;
  background-size: cover;
  min-height: 40vh;
  box-sizing: border-box !important;

  h3 {
    color: var(--text-colorone) !important;
    margin-top: 24px;
    text-align: center;
  }

  .company-content {
    color: var(--text-colorone) !important;
    text-align: justify;
    font-size: 20px;
  }
}
