.apidocuments-main {
  margin-top: -60px;
  background-color: var(--bg-colorone);
  overflow: auto;
  min-height: 80vh;
  .apidocument-module {
    margin-top: 100px;
    margin-bottom: 60px;
    color: var(--text-colorone);
    font-size: 24px;

    a {
      color: var(--text-colorone);
      text-decoration: none;
    }
  }
}
