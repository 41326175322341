.profile-main {
  background-image: var(--profile);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-colorone);
  margin-top: -30px;
  overflow: auto;
  min-height: 100vh;

  .card {
    background-color: var(--bg-colortwo) !important;
    color: var(--text-colorone) !important;

    .card-body {
      font-size: 14px !important;

      .MuiButtonBase-root,
      .MuiButton-root {
        font-size: 12px !important;
      }
    }

    .card-title {
      font-size: 16px !important;
      color: var(--orange) !important;
    }
  }

  .tab-main {
    min-height: 50vh;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: var(--bg-colorone);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    padding: 10px;
  }

  .tab-content {
    background-color: var(--bg-colorone);
    min-height: 60vh;
    border-radius: 15px;
    padding: 25px;
    border: 1px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--text-colorone) !important;
  background-color: var(--bs-colorone) !important;
  border-color: var(--orange) !important;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: 0 0;
  border: var(--bg-colorone) solid 2px !important;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  color: var(--text-colortwo);
}

.user-info {
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  border: 0px !important;
}

.userinfo-main {
  display: flex;
}

.card-title-security {
  margin-bottom: 43px !important;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: #22577a !important;
  margin: 15px;
  padding: 5px;
  width: 12%;
}

.country-list {
  height: 250px;
  overflow-y: scroll;
}

.security-table {
  height: 350px !important;
}

.MuiTableCell-root {
  color: var(--text-colorone) !important;
  background-color: var(--bg-colorone);
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: #22577a !important;
}

.MuiTableContainer-root {
  background-color: var(--bg-colorone) !important;
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: #22577a !important;
}

.header-logs {
  color: var(--text-colorone);
}

.modal-list {
  max-height: 300px;
  overflow-y: scroll;
}

.modal-title {
  color: var(--text-colorone);
  font-size: 16px !important;
}

.modal-header {
  background-color: var(--bg-colorone);
  border-bottom: 0px !important;
  border-radius: 0px !important;
}

.modal-body {
  background-color: var(--bg-colorone);

  ul {
    li {
      color: var(--text-colorone);
    }
  }
}

.commission-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .commission-table {
    margin-top: 100px;
    margin-bottom: 50px;
  }
}

.btn-security {
  background-color: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
}

.btn-security:hover {
  color: var(--orange) !important;
}

.security-main {
  Button {
    background-color: var(--bg-colorthree) !important;
  }
}

.qrcode-frame {
  height: 220px;
}

.withdraw-history-p {
  height: 400px;
  overflow-y: scroll;
}

.wit-his-main {
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.table-row.MuiTableCell-root {
    font-size: 10px;
  }

  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.table-row.MuiTableCell-root {
    font-size: 12px;
    padding: 0;
  }
}

.p-w-history {
  color: var(--text-colorone);
}

.deposit-his-main {
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.table-row.MuiTableCell-root {
    font-size: 10px;
  }

  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.table-row.MuiTableCell-root {
    font-size: 12px;
    padding: 0;
  }
}

.mb-2.text-muted.card-subtitle.h6 {
  color: var(--text-colorone) !important;
}

.mb-5.text-muted.card-subtitle.h6 {
  color: var(--text-colorone) !important;
}

.mb-4.text-muted.card-subtitle.h6 {
  color: var(--text-colorone) !important;
}