.icon-round {
  .MuiButtonBase-root,
  .MuiButton-root,
  .MuiSvgIcon-root {
    font-size: 30px !important;
  }
}
.icon-round :hover {
  background-color: transparent !important;
  color: var(--orange) !important;
}

.dialog-theme {
  background-color: var(--bg-colorone) !important;
  border-radius: 0px !important;
  color: var(--text-colorone);
}
