.contact-main {
  background-position: center;
  background-image: var(--contact);
  background-color: var(--bg-colorone);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  margin-top: -60px;
  overflow: auto;
  .contact-module {
    margin-top: 50px;
    .text-contact {
      color: var(--text-colorone);
      padding: 120px;
    }
    .head {
      font-size: 22px;
      color: var(--text-colorone);
    }

    a {
      background: #22577a;
      color: #fff;
      border: 1px #fff solid;
      padding: 5px 15px;
      margin: 5px;
      text-decoration: none;
    }
    a:hover {
      background: #fff;
      color: #22577a;
      border: 1px #22577a solid;
    }
  }
}
