.sec-howitworks {
  padding: 50px 0;
  background: var(--bg-colorone);
}

.sec-howitworks h2 {
  font-size: 14px;
  color: var(--text-colorone);
  font-weight: 700;
}

.howit-img {
  height: 210px;
}

.apply-section {
  min-height: 30vh;
  background-color: var(--bluecolor);
  color: var(--text-colorone);
}

.content-apply {
  text-align: center;
  font-size: 26px;
  color: var(--text-colorthree);
  font-weight: 500;
  padding-top: 60px;
}

.btn-holder {
  margin: 0;
  text-align: center;
  margin-top: 60px;
}

.apply-btn {
  width: 100px;
  background-color: var(--btn-one);
  color: var(--btn-one-hover);
  font-weight: 500;
  border: 1px solid var(--btn-one);
}

.apply-btn:hover {
  background-color: var(--btn-one-hover);
  color: var(--btn-one);
  border: 1px solid var(--btn-one);
}
.where-to-buy-top {
  background-image: url("../../../assets/images/bgeasy.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  background-color: black;
  margin-top: -30px;
}

.findbox {
  background-color: var(--bg-colorone);
  height: 350px;
  width: 400px;
  border-radius: 5px;
  margin-top: 100px;
  margin-top: 100px !important;

  .title-find {
    font-weight: 700;
    color: var(--text-colorone);
  }

  .content-find {
    font-size: 18px;
    padding-top: 10px;
    padding-left: 10px;
    color: var(--text-colorone);
  }

  .find-btn {
    background-color: var(--btn-one);
    border: 1px solid var(--btn-one);
    color: var(--btn-one-hover);
  }

  .find-btn:hover {
    background-color: var(--btn-one-hover);
    color: var(--btn-one);
    border: 1px solid #3838384d;
  }
}

.separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-colorone);
}

.separator .line {
  height: 1px;
  flex: 1;
  background-color: var(--bg-colorone);
}

.separator h2 {
  padding: 0 2rem;
}

.item-count {
  color: #f40071;
  display: table-cell;
  height: 40px;
  transform: rotate(-45deg);
  vertical-align: middle;
  width: 40px;
}

.howit-text {
  padding: 0 25px;
  color: var(--text-colorone);
}

.howit-text p {
  font-size: 18px;
  color: var(--text-colorone);
}

.howit-text h3 {
  font-size: 26px;
  font-weight: 700;
}

.sec-global {
  padding: 50px 0;
  background-color: var(--bluecolor);
}
