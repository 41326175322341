.deposit-main {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -30px;
  overflow: auto;

  .deposit {
    margin-top: 60px;
    min-height: 80vh;
  }

  .deposit-module {
    color: var(--text-colorone);
    min-height: 600px;
    width: 700px;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }

  .deposit-texts {
    color: var(--text-colorone) !important;
  }
  .MuiInputBase-root,
  .MuiSelect-icon {
    color: var(--text-colorone) !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--text-colorone) !important;
  }
  .btn-primary {
    background-color: var(--bluecolor);
    border-color: var(--bluecolor);
    color: var(--text-colorthree);

    &:hover {
      background-color: var(--text-colorthree);
      border-color: var(--bluecolor);
      color: var(--bluecolor);
    }
  }
}
