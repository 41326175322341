.modal-title {
  color: var(--text-colorone);
  font-size: 20px;
}

.modal-header {
  background-color: var(--bg-colorone);
  border-bottom: 0px !important;
  border-radius: 0px !important ;
}

.modal-body {
  background-color: var(--bg-colorone);
}
